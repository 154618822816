.settings-panel {
  .ant-collapse-header {
    height: auto;
    padding: 0.75em 2em;
    text-align: left;
    width: 100%;
    font-weight: bold;
    &:hover,
    &.active {
      box-shadow: inset 5px 0 0 $muc-brand-primary-color !important;
    }
  }
  .ant-collapse-content-box {
    display: flex;
    justify-content: space-between;
    span {
      padding-left: 0.75em;
    }
  }
}
