.widget {
  &-trend {
    width: 100%;

    &-current {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.65em;

      &-indicator {
        font-size: 1.3em;
        color: $muc-badge-gray;
        line-height: 1.2;

        &-trend {
          &.positive {
            color: $muc-positive-trend;
          }

          &.negative {
            color: $muc-danger;
          }
        }
      }

      &-value {
        @extend .light;
        font-size: 3.75em;
        line-height: 1.2;
        margin-left: 0.15em;

        &-label {
          font-size: 0.5em;
        }
      }
    }

    &-sublabel {
      color: $muc-badge-gray;
      text-align: center;

      button {
        background: none;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
        color: $muc-primary;
        @extend .bold;
      }
    }
  }
}

.badged-header {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    line-height: 1;
    + .badge {
      margin-left: 0.75em;
      border: 1px solid transparent;
      margin-top: -0.45em;
    }
  }
}

.card-footer-left {
  position: relative;
}

.page-changer {
  display: flex;
  flex-direction: row;
}

.page-size-dropdown {
  text-transform: capitalize;
}

.muc-table {
  table {
    th {
      background-color: transparent;
      border-bottom: 3px solid $muc-text-light-gray;
      font-size: 15px;
      @extend .bold;
      text-transform: capitalize;
    }

    tr td {
      border-bottom: 1px solid $muc-text-light-gray;
    }
  }

  .actions {
    text-transform: capitalize;

    button[type="link"] span[role="img"] {
      margin-right: 0.25em;
    }
  }
}

.document-uploaded-date {
  text-transform: capitalize;
}

.uploaded-document-actions {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
