/* light theme */

$muc-light-font-light: $muc-brand-light-grey;
$muc-light-font: $muc-brand-grey;
$muc-light-font-dark: $muc-brand-dark-grey;
$muc-light-badge-gray: #80868b;
$muc-badge-gray: $muc-light-badge-gray;
$muc-light-active-navigation: #fff8ee;
$muc-light-background: #f8f8f8;

$muc-light-border: #e8e8e8;
$muc-light-divider: #b4bcc3;

body.light {
    color: $muc-light-font;
    background-color: $muc-light-background;

    #navigation-buttons {
        #nav-links {
            li {
                a {
                    &:hover,
                    &.current {
                        background-color: $muc-light-active-navigation !important;
                    }
                }
            }
        }
    }

    .card .card-header .icon-gray,
    .card .card-footer .icon-gray,
    .ant-timeline-item-label {
        color: $muc-light-badge-gray;
    }

    .card .card-footer .pagination button,
    #console-side-nav-footer .ant-btn-link {
        color: $muc-black;
    }
    #console #navigation-header #header-search .search-icon {
        color: $muc-brand-light-grey;
    }
    #console #navigation-header #header-search.open .search-icon .anticon-close,
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled],
    .ant-select.ant-select-borderless.ant-select-disabled {
        color: $muc-brand-dark-grey;
    }
    #console-side-nav .console-side-nav-result-data-meta {
        color: $muc-text-light-gray;
    }

    .card,
    .card .card-header + .card-body,
    .card .card-header + .card-micro-header,
    .card .card-micro-header + .card-body,
    .card .card-footer .card-footer-left,
    .card .card-footer .card-footer-right,
    .card .card-footer + .card-body,
    .card .card-footer .pagination .current-page,
    .card .card-body + .card-footer,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button,
    #console #console-main-content #navigation-sub-header,
    #console #console-main-content,
    #console #navigation-header,
    #console #navigation-header #navigation-dropdown,
    #console #navigation-header #navigation-dropdown-company-list,
    #console #navigation-header #navigation-dropdown-company-list .location-filter,
    #console #navigation-header #header-search.open .search-icon,
    #console-side-nav-footer,
    #console-side-nav-filter,
    #console-side-nav #powered-by-container,
    .ant-table-pagination,
    hr {
        border-color: $muc-light-border;
    }

    .card .table table thead,
    .card .table table tbody tr {
        border-color: red;
    }

    #console #navigation-sub-header main #current-console-title .header-tab-nav li button.selected,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:hover,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:focus {
        border-bottom-color: $muc-light-background;
    }
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled],
    .ant-select.ant-select-borderless.ant-select-disabled {
        border-color: $muc-light-background;
    }
    .card,
    #console #navigation-header #header-search,
    #console #navigation-header #navigation-dropdown-company-list,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button {
        background: #fff;
    }
    .badge,
    #console-side-nav .console-side-nav-result-data-badge {
        background: $muc-badge-gray;
    }

    #console #navigation-header #navigation-buttons #nav-links li button:hover,
    #console #navigation-header #navigation-buttons #nav-links li button.current,
    #console #navigation-header #navigation-buttons #nav-links li a:hover,
    #console #navigation-header #navigation-buttons #nav-links li a.current,
    #console #navigation-header #navigation-dropdown-company-list button.selected,
    #console #navigation-header #navigation-dropdown-company-list button:hover,
    #console #navigation-header #navigation-dropdown-company-list button:focus,
    #console-side-nav-footer button:hover,
    #console-side-nav-footer button.active,
    #console-side-nav .console-side-nav-result.selected,
    #console-side-nav .console-side-nav-result:hover,
    #console-side-nav-settings .ant-collapse-header .ant-collapse:hover,
    .ant-collapse .ant-collapse-item .ant-collapse-header:hover {
        background: $muc-light-active-navigation;
    }

    .ant-timeline-item-head .icon-wrapper {
        background: $muc-light-divider;
    }

    .ant-layout,
    .card .card-micro-header,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button.selected,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:hover,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:focus,
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled],
    .ant-select.ant-select-borderless.ant-select-disabled {
        background-color: $muc-light-background;
    }

    #powered-by-container,
    #console header,
    #navigation-sub-header,
    #console-side-nav {
        background-color: $muc-white;
    }
    .ant-timeline-item-head {
        color: $muc-white;
    }
    .ant-tag {
        &-green {
            background: $muc-positive-trend;
            color: #fff;
            border-color: $muc-positive-trend;
        }
        &-volcano {
            background: $muc-danger;
            color: #fff;
            border-color: $muc-danger;
        }
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-head .icon-wrapper {
        border-color: $muc-light-background;
    }
    .ant-timeline.ant-timeline-label:before {
        background: #c7ced3;
    }

    .ant-timeline-item-head .icon-wrapper.approve {
        background: $muc-primary;
    }
    .ant-timeline-item-head .icon-wrapper.create {
        background: $muc-positive-trend;
    }
    .ant-timeline-item-head .icon-wrapper.delete {
        background: $muc-danger;
    }
    .ant-timeline-item-head .icon-wrapper.deny {
        background: $muc-danger;
    }
    .ant-timeline-item-head .icon-wrapper.download {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.export {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.import {
        background: $muc-positive-trend;
    }
    .ant-timeline-item-head .icon-wrapper.login {
    }
    .ant-timeline-item-head .icon-wrapper.logout {
    }
    .ant-timeline-item-head .icon-wrapper.report {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.update {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.upload {
        background: $muc-positive-trend;
    }

    .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background: $muc-light-divider;
    }


    .dashboard-card-container .card .card-title,
    .dashboard-cost-graph .card-title {
        border-bottom: 1px solid $muc-light-border;
    }

    .global-batch-filter-label-text{
        color: black
    }
}