//brand colors
$muc-brand-light-grey: #9a9a9b;
$muc-brand-grey: #2c353e;
$muc-brand-dark-grey: #0e2030;
$muc-brand-primary-color: #fbb040;
$muc-text-light-gray: #b4bcc3;

:root {
  --muc-brand-light-grey: #9a9a9b;
  --muc-brand-grey: #2c353e;
  --muc-brand-dark-grey: #0e2030;
  --muc-brand-primary-color: #fbb040;
  .light {
    --muc-text-light-svg: black;
  }
  .dark {
    --muc-text-light-svg: #b4bcc3;
  }
}

//theme agnostic
$muc-primary: #4088fb;
$muc-danger: #f4563a; /* antd #ff4d4f; */
$muc-danger-glow: #f4563a40; /* --muc-danger + 25% alpha */
$muc-positive-trend: #85c75c;

// warning
$muc-warning: $muc-brand-primary-color;
$muc-warning-border: rgba($muc-warning, 0.8);
$muc-warning-glow: rgba($muc-warning, 0.4);
$muc-warning-light: rgba($muc-warning, 0.2); // #fddFb2;

// grey scale
$muc-black: black;
$muc-text-shadow-color: rgba($muc-black, 0.12);
$muc-box-shadow-color: rgba($muc-black, 0.045);
$muc-white: white;

// TODO: pull colors from the stylesheets
//:export {
//  danger: $muc-danger
//}

.color-electric {
  color: $muc-brand-primary-color;
}

.positive {
  color: $muc-positive-trend;
}

.negative {
  color: $muc-danger;
}
