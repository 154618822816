$when-type-width: 125px;

.muc-when-picker {
  display: flex;
  flex-direction: row;

  .muc-when-type-select {
    width: $when-type-width;
  }

  .muc-when-select {
    width: calc(100% - #{$when-type-width});
  }
}
