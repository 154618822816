#console-side-nav {
  transition: none;
  > div {
    display: flex;
    flex-direction: column;
  }

  &-filter {
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-right: 5px;
    border-bottom: 1px solid transparent;
    min-height: 50px;

    input {
      border: none;
      background: none;
      width: 100%;
      margin-left: 5px;
    }
  }

  &-results {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .console-side-nav-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    padding: 5px 15px;

    cursor: pointer;

    &-data {
      flex-grow: 1;
      line-height: 1.2;
      font-size: 0.9375rem;
      padding-right: 7px;

      &-meta {
        font-size: 0.8em;
        text-transform: uppercase;
        @extend .bold;
      }

      &-badge {
        border-radius: 100px;
        padding: 4px 10px;
        font-size: 0.85em;
        @extend .bold;
        color: #fff;
        border: 1px solid transparent;
        line-height: 1;
      }
    }

    &.selected,
    &:hover {
      box-shadow: inset 5px 0 0 $muc-brand-primary-color;
    }
  }

  &-footer {
    flex-grow: 0;
    flex-shrink: 1;
  }

  #powered-by-container {
    border-top: 1px solid transparent;
    font-size: 0.65rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 3px;
    justify-content: center;

    #powered-by-verbiage {
      padding: 5px;
    }

    #powered-by-muc-logo {
      padding: 5px;

      img,
      svg {
        width: 95px;
      }
    }
  }
}
