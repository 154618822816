$audit-log-filter-width: 525px;

#audit-log-container {
  display: flex;
  flex-direction: row;

  .ant-row {
    width: 100%;

    #audit-log-table-filters {
      width: $audit-log-filter-width;
    }

    #audit-log-table {
      width: calc(100% - #{$audit-log-filter-width} - 16px /* gutter size of row correction */);
    }
  }
}

#audit-log-results {
  .ant-collapse-header {
    @extend .bold;
    font-size: 1rem;
    padding-left: 3.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.6rem;
    span {
      position: static;
      top: auto;
      left: auto;
      margin-right: 0.35rem;
    }
  }
  .ant-collapse-content-box {
    padding: 0;
  }
}

.ant-timeline.ant-timeline-label {
  .ant-timeline-item,
  .ant-timeline-item-label,
  .ant-timeline-item-tail,
  .ant-timeline-item-head,
  .ant-timeline-item-content,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    // base reset of some elements from Ant's defaults
    position: static;
    padding: 0;
    font-size: 0.875rem;
    transform: none;
  }
  position: relative;
  &:before {
    content: "";
    width: 3px;
    background: red;
    display: block;
    height: calc(100% + 2rem);
    position: absolute;
    left: 7.05rem;
    top: -1.25rem;
  }
  .ant-timeline-item {
    display: flex;
    width: 100%;
    margin: 1.25rem 0;
    .ant-timeline-item-tail {
      display: none;
    }
    .ant-timeline-item-label {
      width: 80px !important;
      flex: 0 0 auto;
      @extend .bold;
      height: 2.625rem;
      margin-right: 0.75rem;
      padding-top: 0.7rem;
    }
    .ant-timeline-item-head {
      flex: 0 0 auto;
      background: none;
      .icon-wrapper {
        height: 3.25rem;
        width: 3.25rem !important;
        border-radius: 100rem;
        position: relative;
        font-size: 1.25rem;
        border: 0.5rem solid red;
        margin: -0.25rem 0 0 -0.25rem;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .ant-timeline-item-content {
      flex: 1 1 auto;
      margin-left: 0.5rem;
      .card-body {
        padding: 0.5rem 1rem;
      }
      .audit-log-card-content {
        display: flex;
        flex-wrap: wrap;

        .audit-log-card-content-left {
          flex: 0 0 auto;
          .audit-log-user {
            h4.user-name,
            .badge {
              display: inline-block;
            }
            h4.user-name {
              margin-right: 0.5rem;
              margin-bottom: 0;
            }
            .badge {
              font-size: 0.6875rem;
              text-transform: capitalize;
            }
          }
          .audit-log-card-content-breadcrumbs {
            text-transform: uppercase;
            font-size: 0.6875rem;
            font-weight: 500;
            span {
              margin: 0 0.3rem;
            }
          }
        }
        .audit-log-card-content-middle {
          flex-grow: 8;
          .audit-log-action {
            display: flex;
            font-size: 0.75rem;
            h4 {
              text-align: right;
              width: 6rem;
              flex-shrink: 0;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            .value {
              flex-grow: 1;
            }
          }
        }
        .audit-log-card-content-right {
          flex: 0 0 auto;
          padding-left: 1rem;
          text-align: right;
          display: flex;
          a {
            @extend .bold;
            align-self: center;
            font-size: 0.75rem;
          }
        }

        .audit-log-details-container {
          .ant-collapse-header {
            height: 0;
            top: -30px;
            right: -20px;
            float: right;
          }
        }
      }
    }
  }
}
