.ant-steps {
  padding-bottom: 30px !important;
}
.steps-action {
  display: flex;
  justify-content: space-between;
}


#site-surveys-general-form,
#site-surveys-contacts-form,
#site-surveys-compressors-form,
#site-surveys-motors-form,
#site-surveys-gear-boxes-form,
#site-surveys-lighting-form,
#site-surveys-hvac-form,
#site-surveys-documents-form {
  padding: 40px;

  .add-field {
    display: flex;
    justify-content: space-around;
  }

  &.card {
    margin-bottom: 30px;
  }

  .site-surveys-contacts-form-piece,
  .site-surveys-compressors-form-piece,
  .site-surveys-motors-form-piece,
  .site-surveys-gear-boxes-form-piece,
  .site-surveys-lighting-form-piece,
  .site-surveys-hvac-form-piece {
    margin-bottom: 24px;
    padding: 30px 30px 10px 30px;
    position: relative;

    .close-icon {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }

  .site-surveys-motors-form-piece {
    column-gap: 50px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    .ant-row.ant-form-item {
      width: calc(50% - 50px);
      .ant-form-item-control-input-content {
        padding-left: 25px;
      }
    }
  }

  .site-surveys-lighting-form-piece {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    .ant-row.ant-form-item {
      padding: 0 15px;
      width: 50%;
    }
  }
}


.dark {
  .site-surveys-contacts-form-piece,
  .site-surveys-compressors-form-piece,
  .site-surveys-motors-form-piece,
  .site-surveys-gear-boxes-form-piece,
  .site-surveys-lighting-form-piece,
  .site-surveys-hvac-form-piece {
    border: 1px solid #2e4a64 !important;

    .close-icon {
      color: #4088fb;
      &:hover {
        color: #2e4a64;
      }
    }
  }
}