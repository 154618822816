

#division-dashboard {
  padding: 0 50px;
  position: relative;


  .dashboard-selection-bar {
    display: flex;
    flex-direction: row;
    height: 45px;
    justify-content: space-between;
    padding: 10px 15px;

    div > svg {
      position: relative;
      top: -1px;
    }

    div:nth-child(2) {
      b {
        padding: 0 5px;
      }

      .ant-picker {
        border: none;

        .ant-picker-input > input {
          font-size: 9px;
          text-align: center;
          width: 55px;
        }
      }
    }

    .ant-select {
      width: 125px;

      .ant-select-selector {
        border: none;
        font-weight: bold;

      }

      .ant-select-arrow {
        color: initial;
      }
    }
  }

  .dashboard-card-container {
    column-gap: 15px;
    display: flex;
    flex-wrap: wrap;
    grid-auto-rows: 1fr;
    justify-content: space-between;
    padding: 30px 0;
    position: relative;
    row-gap: 15px;
    width: 100%;

    .card {
      flex: 1 0 calc(25% - 15px);
      height: max-content;
      min-height: 200px;

      .card-title {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 68px;
        padding: 10px 15px;
        text-align: center;

        b {
          padding-right: 5px;
        }
      }

      .card-content {
        align-items: center;
        display: flex;
        font-size: 10px;
        justify-content: center;
        padding: 5px 15px;
        position: relative;

        .card-icon-container {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          justify-content: space-between;
          padding-right: 4px;
          position: relative;

          svg {
            padding: 4px;
          }

          span {
            height: 20px;
          }
        }

        .card-data-number {
          font-size: 55px;
        }

        .card-data-suffix {
          font-size: 18px;
          padding-left: 5px;
          position: relative;
          top: 15px;
        }
      }

      .card-data-footer {
        text-align: center;

        b {
          padding-right: 5px;
        }
      }
    }
  }

  .dashboard-cost-graph {
    min-height: 300px;

    .card-title {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;

      div:nth-child(2) span:not(.ant-checkbox) {
        padding-left: 10px;
      }
    }
    .card-content {
      padding: 10px 15px;
    }
  }

}