$card-padding: 15px;

.card {
    border: 1px solid transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    &-header,
    &-footer {
        .anticon {
            margin: 0 0.25em;
        }
        .icon-larger {
            .anticon {
                font-size: 1.25em;
            }
        }
        .value {
            font-weight: normal;
        }
        button {
            background: none;
            border-radius: 0;
            border: none;
            padding: 0;
            color: $muc-primary;
            margin: 0 5px;
        }
        .ant-space {
            margin: 0 10px;
        }
    }
    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        @extend .bold;

        &-left {
            flex-grow: 1;
            text-align: left;
            padding: $card-padding;
        }
        &-center {
            flex-grow: 2;
            text-align: center;
            padding: $card-padding;
        }
        &-right {
            flex-grow: 1;
            text-align: right;
            padding: $card-padding;
        }
        + .card-body,
        + .card-micro-header {
            border-top: 1px solid transparent;
        }
        button {
            @extend .bold;
        }
    }
    &-micro-header {
        @extend .bold;
        text-align: center;
        padding: 3px 0;
        + .card-body {
            border-top: 1px solid transparent;
        }
    }
    &-footer {
        @extend .card-header;
        justify-content: space-between;
        align-items: stretch;
        &-left {
            flex-grow: 0;
            flex-shrink: 1;
            text-align: left;
            border-right: 1px solid transparent;
            padding: $card-padding;
        }
        &-center {
            flex-grow: 2;
            text-align: center;
            padding: $card-padding;
        }
        &-right {
            flex-grow: 1;
            text-align: right;
            flex-grow: 0;
            flex-shrink: 1;
            border-left: 1px solid transparent;
            padding: $card-padding;
        }
        + .card-body {
            border-top: 1px solid transparent;
        }
        .pagination {
            padding: 0;
            display: flex;
            align-items: stretch;
            .current-page {
                padding: 0 1.5em;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                display: flex;
                align-items: center;
                text-transform: none;
            }
            button {
                padding: 0.5em;
                &.placeholder {
                    opacity: 0.15;
                    cursor: default;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
    &-body {
        padding: 20px 30px 30px 30px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        > div {
            width: 100%;
        }
        .card {
            &-body {
                padding: 15px;
            }
        }
        &-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1.5em;
            width: 100%;
            h2 {
                margin-bottom: 0;
                + .badge {
                    margin-left: 0.75em;
                    border: 1px solid transparent;
                    margin-top: -0.45em;
                }
            }
            .actions {
                margin-left: auto;
                button,
                a {
                    color: $muc-primary;
                    background: none;
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    @extend .bold;
                    margin: 0 0 0 1em;
                }
            }
        }
        + .card-footer {
            border-top: 1px solid transparent;
        }
    }
    .table {
        font-size: 0.9375rem;
        table {
            width: 100%;
            td,
            th {
                padding: 0.75em 0.5em;
            }
            thead {
                border-bottom: 3px solid transparent;
            }
            tbody {
                tr {
                    border-bottom: 1px solid transparent;
                }
                .table-actions {
                    text-align: right;
                    width: 100%;
                    justify-content: flex-end;
                    text-transform: capitalize;
                }
            }
        }
    }
    .ant-table-pagination {
        margin: 30px -30px -30px -30px;
        border-top: 1px solid transparent;
        display: flex;
        justify-content: flex-start;
        li {
            margin: 0 -1px 0 0;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-pagination-item-link,
        .ant-pagination-item {
            background: none;
            border-radius: 0;
            border-top: none;
            border-bottom: none;
            @extend .bold;
            // padding: .75em 1em;
        }
        .ant-pagination-options {
            order: -1;
            margin-right: auto;
            margin-left: -1px;
        }
    }

    .form-footer-controls {
        text-align: center;
        width: 100%;

        .ant-form-item {
            display: inline-block;
        }
    }
}
