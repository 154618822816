#admin-settings-container {
  padding: 1em;

  .ant-form-vertical {
    padding: 0.25em;
    text-align: center;

    .document-uploaded-date {
      padding: 0 0 1.5em 0;
    }
  }
}
