.ant-form {
  &-item {
    margin-bottom: 0.875rem;
    &-label {
      width: 100%;

      label {
        float: left;
      }
    }
  }
}

/// this hides the up/down arrow keys on antd's InputNumber component
.ant-input-number-handler-wrap {
  display: none;
}
