#login-content {
    width: 350px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    max-width: calc(100vw - 60px);
    @media screen and (max-height: 530px) {
        position: static;
        left: auto;
        top: auto;
        transform: none;
        margin: 0 auto;
        padding-top: 30px;
    }
    .login-logo {
        svg {
            width: 350px;
        }
    }

    button {
        min-width: 100px;
    }

    #login-form-inputs {
        margin: 1em 0 0 0;

        .ant-form-item-control-input-content {
            .ant-input:focus,
            .ant-input:hover,
            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
            .ant-input-affix-wrapper:hover {
                border-color: $muc-warning !important;
            }

            .ant-input:focus {
                box-shadow: 0 0 0 2px $muc-warning-glow;
            }

            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
                box-shadow: 0 0 0 2px $muc-warning-glow;

                .ant-input:focus {
                    box-shadow: none;
                }
            }
        }

        #login-buttons-row {
            padding-top: 1em;

            .ant-col {
                display: flex;
                justify-content: center;
            }
        }
    }
}
