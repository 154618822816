.switch-list {
    li {
        margin-bottom: 5px;

        button {
            margin-right: 5px;
        }

        .sortable-label {
            &:hover {
                cursor: grab;
            }
            user-select: none;
        }
    }
}
