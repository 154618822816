$nav-logo-collection-width: 14.25rem;
$nav-header-height: 4.06rem;
$nav-sub-header-height: 2.75rem;
$nav-logo-collection-width: 14.25rem;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.4em;
}

.badge {
  color: #fff;
  padding: 0 0.85em;
  border-radius: 100px;
  display: inline-block;
  white-space: nowrap;
}

#console {
  height: 100vh;
  margin: 0;
  width: 100vw;

  #navigation-header {
    height: $nav-header-height;
    padding: 0;
    width: 100%;
    z-index: 1;
  }
  #navigation-sub-header {
    height: auto;
    text-transform: capitalize;

    main {
      height: auto;

      #current-console-title {
        height: auto;
        // TODO: why not just full width?
        // width: $nav-logo-collection-width;
        padding-left: 30px;

        h2 {
          padding: 8px 0 0 0;
          margin-bottom: 4px;
        }

        .header-tab-nav {
          list-style: none;
          display: flex;
          padding: 5px 0 0;
          margin: 0 0 -1px;

          li {
            padding: 0;
            white-space: nowrap;
            margin: 0 5px 0 0;

            button {
              border: 1px solid transparent;
              padding: 7px 20px 10px 20px;
              border-radius: 4px 4px 0 0;

              &.selected,
              &:hover,
              &:focus {
                border-bottom: 1px solid transparent;
                padding-top: 12px;
                margin-top: -5px;
              }
            }

            &.header-tab-nav-action {
              button {
                background: none !important;
                border: none;
                border-radius: 0;
                color: $muc-primary;
                @extend .bold;
              }
            }
          }
        }
      }
    }
  }

  #console-main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-left: 1px solid transparent;
    // TODO: Figure out what this is
    max-width: calc(100vw - 14.25rem);
    #navigation-sub-header {
      flex-grow: 0;
      border-bottom: 1px solid transparent;
      min-height: auto;
    }

    #console-content {
      flex-grow: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      &-container {
        // max-width: 1480px;
        margin: 0 auto;
        padding: 30px 30px 150px 30px;
      }
    }
  }

  #console-body {
    flex-direction: row;

    #console-side-nav {
      flex: 0 0 $nav-logo-collection-width !important;
      max-width: $nav-logo-collection-width !important;
      min-width: $nav-logo-collection-width !important;
      width: $nav-logo-collection-width !important;
    }
  }

  button.no-style {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: inherit;

    &.placeholder {
      span {
        opacity: 0.15;
      }
    }
  }
}
