$muc-dark-font-light: #eaeaea;
$muc-dark-font: #c7ced3;
$muc-dark-font-dark: $muc-brand-light-grey;

/*--muc-dark-active-navigation: #fff8ee;*/
$muc-darkest-background: #0e2030;
$muc-dark-background: #192f43;
/*var(--muc-brand-dark-grey);*/
$muc-dark-border: #0c1f31;
$muc-dark-divider: #0b1926;
$muc-darkest-border: #0b1926;
$muc-danger-glow-dark: #bd924b; /* --muc-danger + 50% alpha */
$muc-button-highlight-background: #233c53;
$muc-dark-table-border: #2e4a64;

body.dark {
    color: $muc-dark-font;

    #powered-by-muc-logo,
    #navigation-header,
    .login-logo {
        svg .cls-5 {
            fill: $muc-dark-font;
        }

        svg .cls-6 {
            fill: $muc-dark-font-dark;
        }
    }

    #navigation-buttons {
        #nav-links {
            li {
                a {
                    color: $muc-white;
                }
            }
        }
    }

    .ant-dropdown-menu-item{
        background-color: $muc-button-highlight-background;
    }

    #alert-panel, #settings-panel {
        .ant-badge {
            color: $muc-white;
        }
    }

    .dark-toggle {
        color: $muc-white;
    }

    .alert-overlay {
        .ant-popover-title {
            color: $muc-darkest-background;
            background-color: $muc-brand-primary-color;
        }
        .ant-popover-inner-content {
            font-size: 15px;
            background-color: $muc-dark-background !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    button,
    .ant-layout-header,
    #console-side-nav-footer .ant-btn-link,
    input::placeholder,
    .card-body-heading h2 + .badge,
    .ant-picker .anticon,
    .widget-trend-current-indicator,
    .card-footer .pagination button,
    .ant-dropdown-menu a,
    .ant-table,
    .ant-table thead th,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-pagination-item-link,
    .ant-pagination-item,
    .ant-pagination-item a,
    .ant-form-item-label > label,
    .ant-input,
    .ant-input-number,
    .ant-select-arrow,
    .ant-form .ant-form-item-control-input-content,
    .ant-input-password .ant-input-password-icon,
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item,
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal,
    .ant-select-tree-treenode,
    #audit-log-results .ant-collapse-header,
    #console-side-nav-settings .ant-collapse-header .ant-collapse,
    .ant-collapse .ant-collapse-item .ant-collapse-header,
    .ant-collapse-content-box,
    .ant-picker-cell,
    .ant-picker-cell.ant-picker-cell-in-view,
    .ant-picker-content th,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
    .ant-picker-header,
    .ant-picker-input > input,
    .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
    .ant-radio-wrapper,
    #division-dashboard > .dashboard-selection-bar .ant-select .ant-select-arrow {
        color: $muc-dark-font;
    }

    hr {
        border-color: $muc-dark-table-border !important;
        margin: 24px 0;
    }

    #console-side-nav .console-side-nav-result-data-meta {
        color: $muc-danger-glow-dark;
    }

    &.dark,
    #console,
    .ant-layout,
    #console-side-nav .console-side-nav-result-data-badge,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button.selected,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:hover,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:focus,
    .badge,
    .ant-picker,
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        background: $muc-darkest-background;
    }

    .ant-header-layout,
    .ant-layout-sider,
    #navigation-sub-header,
    #navigation-header,
    #console #navigation-header #header-search,
    #console #navigation-header #navigation-dropdown-company-list,
    .card,
    .ant-dropdown-menu-item-divider,
    .ant-dropdown-menu-submenu-title-divider,
    .ant-dropdown-menu-item:hover,
    .ant-select-dropdown,
    .ant-select-tree,
    .ant-tree-select .ant-select-selection-item,
    .ant-picker-panel-container,
    .ant-table-filter-dropdown,
    .ant-input-group-addon,
    #division-dashboard > div.card.dashboard-selection-bar input,
    #division-dashboard > div.card.dashboard-selection-bar .ant-picker,
    #division-dashboard .ant-select-selector,
    th.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters,
    .ant-table-cell.ant-table-column-sort {
        background: $muc-dark-background;
    }

    #console #navigation-sub-header main #current-console-title .header-tab-nav li button,
    #console #navigation-header #navigation-dropdown-company-list button.selected,
    #console #navigation-header #navigation-dropdown-company-list button:hover,
    #console #navigation-header #navigation-dropdown-company-list button:focus,
    #console-side-nav .console-side-nav-result.selected,
    #console-side-nav .console-side-nav-result:hover,
    #console #navigation-header #navigation-buttons #nav-links li button:hover,
    #console #navigation-header #navigation-buttons #nav-links li button.current,
    .card-micro-header,
    #console-side-nav-footer .ant-btn-link:hover,
    .ant-dropdown-menu,
    .card .ant-table-pagination .ant-pagination-item-active,
    .ant-input,
    .ant-input-number,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled],
    .ant-select.ant-select-borderless.ant-select-disabled,
    .ant-input-affix-wrapper,
    .ant-table-column-sort,
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal:hover,
    .ant-switch-handle::before,
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover,
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover,
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner,
    .ant-picker-cell-disabled::before,
    .ant-steps-item-wait .ant-steps-item-icon,
    .ant-btn,
    .ant-btn[disabled] {
        background-color: $muc-button-highlight-background;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        // border: 1px solid green;
        -webkit-text-fill-color: $muc-dark-font;
        -webkit-box-shadow: 0 0 0 1000px $muc-button-highlight-background inset;
        // transition: background-color 5000s ease-in-out 0s;
    }

    #console-side-nav .console-side-nav-result-data-badge,
    #console-side-nav-footer,
    #console #navigation-header,
    #console #console-main-content #navigation-sub-header,
    #console-side-nav-filter,
    #console #console-main-content,
    #console #navigation-header #navigation-dropdown,
    #console-side-nav #powered-by-container,
    #console #navigation-header #navigation-dropdown-company-list,
    #console #navigation-header #navigation-dropdown-company-list button.muc-admin,
    #console #navigation-header #navigation-dropdown-company-list .location-filter,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button,
    .card,
    .card-footer,
    .card-footer-left,
    .card-footer-right,
    .card-body,
    .card-micro-header,
    .card-footer .pagination .current-page,
    .card-body-heading h2 + .badge,
    .ant-picker,
    .ant-dropdown-menu,
    .ant-table-pagination,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-pagination-item-link,
    .ant-pagination-item,
    .ant-input,
    .ant-input-number,
    .ant-input-affix-wrapper,
    .ant-tree-select .ant-select-selection-item,
    hr,
    .ant-picker-datetime-panel .ant-picker-time-panel,
    .ant-picker-header,
    .ant-picker-time-panel-column:not(:first-child),
    .ant-picker-panel,
    .ant-btn {
        border-color: $muc-darkest-border;
    }

    .card .table table thead,
    .card .table table tbody tr,
    .ant-table tr th,
    .ant-table tr td,
    .ant-select-dropdown {
        border-color: $muc-dark-table-border;
    }

    #console #navigation-header #navigation-dropdown-company-list button.selected,
    #console #navigation-header #navigation-dropdown-company-list button:hover,
    #console #navigation-header #navigation-dropdown-company-list button:focus,
    #console-side-nav .console-side-nav-result.selected,
    #console-side-nav .console-side-nav-result:hover,
    #console #navigation-header #navigation-buttons #nav-links li button:hover,
    #console #navigation-header #navigation-buttons #nav-links li button.current {
        border: 1px solid $muc-dark-border;
        border-left: none;
        border-right: none;
        margin: -1px 0;
    }

    #console #navigation-header #navigation-buttons #nav-links li button:hover,
    #console #navigation-header #navigation-buttons #nav-links li button.current {
        border: 1px solid $muc-dark-border;
        border-top: none;
        border-bottom: none;
        margin: 0 -1px;
    }

    #console #navigation-sub-header main #current-console-title .header-tab-nav li button.selected,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:hover,
    #console #navigation-sub-header main #current-console-title .header-tab-nav li button:focus {
        border-bottom: 1px solid $muc-darkest-background;
    }

    #console-side-nav .console-side-nav-result.selected,
    #console-side-nav .console-side-nav-result:hover {
        margin: 0;
        border: none;
        box-shadow: inset 5px 0 0 $muc-brand-primary-color, 0 1px 0 $muc-darkest-background, 0 -1px 0 $muc-darkest-background;
    }

    .ant-table-tbody > tr.ant-table-placeholder:hover > td,
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-color: $muc-button-highlight-background;
    }

    .ant-empty-image {
        .ant-empty-img-simple-path,
        .ant-empty-img-simple-ellipse {
            fill: $muc-dark-table-border;
        }
        g.ant-empty-img-simple-g {
            stroke: $muc-darkest-background;
            fill: $muc-darkest-background;
        }
        .ant-empty-img-simple-path {
            stroke: $muc-darkest-border;
        }
    }
    .ant-tag {
        &-green {
            background: #40a010;
            color: #f6ffed;
            border-color: #40a010;
        }
        &-volcano {
            background: #be380c;
            color: #fff2e8;
            border-color: #be380c;
        }
    }

    .ant-timeline-item-label {
        opacity: 0.5;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-head .icon-wrapper {
        border-color: $muc-darkest-background;
    }
    .ant-timeline.ant-timeline-label:before {
        background: #c7ced3;
        opacity: 0.25;
    }

    .ant-timeline-item-head .icon-wrapper {
        background: $muc-light-divider;
    }

    .ant-timeline-item-head .icon-wrapper.approve {
        background: $muc-primary;
    }
    .ant-timeline-item-head .icon-wrapper.create {
        background: $muc-positive-trend;
    }
    .ant-timeline-item-head .icon-wrapper.delete {
        background: $muc-danger;
    }
    .ant-timeline-item-head .icon-wrapper.deny {
        background: $muc-danger;
    }
    .ant-timeline-item-head .icon-wrapper.download {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.export {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.import {
        background: $muc-positive-trend;
    }
    .ant-timeline-item-head .icon-wrapper.login {
    }
    .ant-timeline-item-head .icon-wrapper.logout {
    }
    .ant-timeline-item-head .icon-wrapper.report {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.update {
        background: $muc-brand-primary-color;
    }
    .ant-timeline-item-head .icon-wrapper.upload {
        background: $muc-positive-trend;
    }

    .ant-timeline-item-head .icon-wrapper {
        color: $muc-darkest-border;
    }

    .ant-picker-panel-container,
    .ant-table-filter-dropdown {
        border: 1px solid $muc-dark-border;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }
    .ant-picker-cell,
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active,
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        opacity: 0.4;
    }
    .ant-btn[disabled],
    .ant-btn-primary[disabled] {
        border: initial;
    }
    .ant-picker-cell.ant-picker-cell-in-view {
        opacity: 1;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
    .ant-btn.ant-btn-primary {
        background: #1890ff;
    }
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
        color: #fff;
    }

    .ant-popover-inner-content,
    .ant-popover-arrow-content {
        background: $muc-darkest-background;
    }

    .ant-upload,
    .ant-upload-list-item {
        background: $muc-dark-background;
        border: none;

        .ant-upload-text {
            color: $muc-dark-font-light !important;
        }

        .ant-upload-list-item-name {
            color: $muc-dark-font-light !important;

            &:hover {
                color: black !important;
            }
        }
    }

    .ant-popover-message-title {
        color: white;
    }

    .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: $muc-dark-table-border;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        border-color: rgba(255, 255, 255, 0.15);
        .ant-steps-icon {
            color: rgba(255, 255, 255, 0.3);
        }
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: $muc-dark-table-border;
    }

    .ant-btn-link {
        background-color: transparent;
    }

    // FIXME: this will darken the background of two-tone svg icons, but will also mess with other svg icons -- need
    // more specific class names to only include specific antd icons
    // .anticon {
    //    svg {
    //        path:nth-child(2) {
    //            fill: $muc-dark-background;
    //        }
    //    }
    //}

    .dashboard-card-container > .card > .card-title,
    .dashboard-cost-graph > .card-title {
        border-bottom: 1px solid #0b1926;
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: $muc-brand-primary-color;
        border: none;
        transition: none !important;
    }

    .ant-upload-list-item:hover button.ant-btn {
        background-color: transparent;
        transition: none !important;
        border-color: red;
        transform: translateY(-1.5px);
        color: red;
    }

    .ant-upload-list-item:hover button.ant-btn svg {
        color: red;
    }

    .ant-upload-list-item-card-actions {
        .ant-btn .ant-btn-text .ant-btn-sm .ant-btn-icon-only .ant-upload-list-item-card-actions-btn {
            background-color: $muc-brand-primary-color;
        }
    }

    .ant-modal-content{
        background-color: $muc-dark-background;
    }
    .ant-modal-confirm-title,
    .ant-modal-confirm-content{
        color: $muc-dark-font;
    }
    .ant-picker {
        background-color: #233c53;
    }

    .global-batch-filter-label-text{
        color: white
    }
}
