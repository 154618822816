#console-side-nav-footer {
  border-top: 1px solid transparent;
  width: 14.25rem;

  button {
    height: auto;
    padding: 0.75em 2em;
    text-align: left;
    width: 100%;
    font-weight: normal;
    &:hover,
    &.active {
      box-shadow: inset 5px 0 0 $muc-brand-primary-color;
    }
  }
}

.settings-panel {
  .ant-collapse-header {
    height: auto;
    padding: 0.75em 2em;
    text-align: left;
    width: 100%;
    font-weight: normal;
    &:hover,
    &.active {
      box-shadow: inset 5px 0 0 $muc-brand-primary-color !important;
    }
  }
}