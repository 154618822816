.column-search-filter {
  padding: 10px;
  .ant-input-wrapper {
    margin-right: 10px;
    button {
      position: relative;
      right: -1px;
    }
    .anticon {
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.dark {
  .column-search-filter {
    .ant-input-wrapper {
      .anticon {
        color: #9a9a9b;
        svg {
          color: #9a9a9b;
        }
        svg > path:nth-child(2) {
          fill: #9a9a9b !important;
        }
        &:hover {
          color: #1890ff;
          svg {
            color: #1890ff;
          }
          svg > path:nth-child(2) {
            fill: #1890ff !important;
          }
        }
      }
    }
  }
}

.light {
  .column-search-filter {
    .ant-input-wrapper {
      .anticon {
        color: #9a9a9b;
        svg {
          color: #9a9a9b;
        }
        svg > path:nth-child(2) {
          fill: #9a9a9b !important;
        }
        &:hover {
          color: #1890ff;
          svg {
            color: #1890ff;
          }
          svg > path:nth-child(2) {
            fill: #1890ff !important;
          }
        }
      }
    }
  }
}